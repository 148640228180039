import axios from 'axios';
import { loginFailed } from '../services/auth';
import store from '../store';
import router from '../router';
import max_ex from '../data/max_ex.json';


const BASE_URL = process.env.VUE_APP_API_URL;
const APP_VERSION = process.env.VUE_APP_VERSION;

axios.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json';
    return config;
});

const api = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json', 'Accept-version': APP_VERSION }
});
const apiNoToken = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json', 'Accept-version': APP_VERSION }
});
api.interceptors.request.use((config) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') != 'undefined') {
        config.headers['Auth-Token'] = localStorage.getItem('token');
    }
    if (localStorage.getItem('timestamp') && localStorage.getItem('timestamp') != 'undefined') {
        config.headers['timestamp'] = localStorage.getItem('timestamp');
    }
    if (localStorage.getItem('signature') && localStorage.getItem('signature') != 'undefined') {
        config.headers['signature'] = localStorage.getItem('signature');
    }
    var dataLang = JSON.parse(localStorage.getItem('lang'))
    if (dataLang && dataLang !== 'undefined' && dataLang.lang && dataLang.lang !== 'undefined') {
        config.headers['Accept-Language'] = dataLang.lang === 'il' ? 'he' : dataLang.lang;
    }
    config.headers['Subscriptionid'] = store.getters.getSubscriptionId
    return config;
});

api.interceptors.response.use((response) => {
    localStorage.setItem('timestamp', response.headers['timestamp']);
    localStorage.setItem('signature', response.headers['signature']);
    store.commit('setShowModalError', false);
    //this part shows api reponse error message
    if (response.data && response.data.errorCode && !response.config.swallowError) {
        if (response.data.errorCode !== 'ERROR') {
            store.commit('setShowModalError', true);
        }
        store.commit('setErrorCode', response.data.errorCode);
        store.commit('setErrorMessage', response.data.errorMessage);
        console.log('ErrorCode:' + response.data.errorCode);
        console.log('ErrorMessage:' + response.data.errorMessage);
        if (response.data.errorCode.indexOf('SESSION_EXPIRED') > -1) {
            store.commit('setLoggedIn', false);
            router.push('/login');
        }
        if (response.data.errorCode.indexOf('AMOUNT_NOT_VALID') > -1) {
            router.push('/recent_actions');
        }
        //return Promise.reject(response.data.errorMessage);
    }
    return response;
}, (error) => {

    if (error.config.swallowError && error.response.status === 401) {
        window.clearInterval(store.getters.getWalletBalancesInterval)
    }

    if (!error.config.swallowError) {
        if (error.response) {
            if (error.response.status === 401 || error.response.status == 500) {
                localStorage.removeItem('token');
                localStorage.removeItem('userName');
                localStorage.removeItem('registrationData');
                localStorage.removeItem('selectedTransferOption');
                localStorage.removeItem('selectedBeneficiary');
                localStorage.removeItem('customerFields');
                localStorage.removeItem('beneficiaryFields');
                localStorage.removeItem('fromUnregisteredCalc');

                store.commit('resetAllState');
                store.commit('setLoggedIn', false);
                store.commit('setNotSigned', false);
                store.commit('setShowModalError', true);
                store.commit('setErrorCode', 'SESSION_EXPIRED');
                store.commit('setErrorMessage', '');
                store.commit('setLoggedIn', false);
                router.push('/login');
            } else if (error.response.status == 503) {
                console.log(error.response);
                store.commit('setShowModalError', true);
                store.commit('setErrorCode', 'SERVER_ERROR');
            } else {
                console.log(error.response);
                store.commit('setShowModalError', true);
                store.commit('setErrorCode', '');
                store.commit('setErrorMessage', error.response);
            }
        } else if (error.request) {
            console.log(error.request);
            store.commit('setShowModalError', true);
            store.commit('setErrorCode', 'SERVER_ERROR');
            // store.commit('setErrorMessage', 'Connection Error');// problem connecting to server
        } else {
            console.log(error);
            store.commit('setShowModalError', true);
            store.commit('setErrorCode', '');
            store.commit('setErrorMessage', 'Error');
            console.log(error.message);
        }
    }
});
apiNoToken.interceptors.request.use((config) => {
    if (localStorage.getItem('timestamp') && localStorage.getItem('timestamp') != 'undefined') {
        config.headers['timestamp'] = localStorage.getItem('timestamp');

    }
    if (localStorage.getItem('signature') && localStorage.getItem('signature') != 'undefined') {
        config.headers['signature'] = localStorage.getItem('signature');
    }

    const dataLang = JSON.parse(localStorage.getItem('lang'))
    if (dataLang && dataLang !== 'undefined' && dataLang.lang && dataLang.lang !== 'undefined') {
        config.headers['Accept-Language'] = dataLang.lang === 'il' ? 'he' : dataLang.lang;
    }
    return config;
});

apiNoToken.interceptors.response.use((response) => {
    store.commit('setShowModalError', false);
    //this part shows api reponse error message
    if (response.data && response.data.errorCode) {
        store.commit('setShowModalError', true);
        store.commit('setErrorCode', response.data.errorCode);
        store.commit('setErrorMessage', response.data.errorMessage);
        console.log('ErrorCode:' + response.data.errorCode);
        console.log('ErrorMessage:' + response.data.errorMessage);
        if (response.data.errorCode == 'REGISTRATION_TOKEN_EXPIRED') {
            store.commit('setLoggedIn', false);
            router.push('/registration');
        }
        if (response.data.errorCode == 'SMS_WRONG' || response.data.errorCode == 'SMS_EXPIRED' || response.data.errorCode == 'REGISTRATION_TOKEN_NOT_FOUND') {
            store.commit('setShowModalError', false);
            return response;
        }
        //return Promise.reject(response.data.errorMessage);
    }
    return response;
}, (error) => {

    if (error.config.swallowError && error.response.status === 401) {
        window.clearInterval(store.getters.getWalletBalancesInterval)
    }

    if (!error.config.swallowError) {
        if (error.response) {
            if (error.response.status === 401 || error.response.status == 500) {
                router.push('/login');
            } else {
                console.log(error.response);
                store.commit('setShowModalError', true);
                store.commit('setErrorCode', '');
                store.commit('setErrorMessage', error.response);
            }
        } else if (error.request) {
            console.log(error.request);
            store.commit('setShowModalError', true);
            store.commit('setErrorCode', 'SERVER_ERROR');
            // store.commit('setErrorMessage', 'Connection Error');
        } else {
            console.log(error);
            store.commit('setShowModalError', true);
            store.commit('setErrorCode', 'generalErrorMsg');
            store.commit('setErrorMessage', 'Error');
            console.log(error.message);
        }
    }
});

/* REGISTRATION */
export function registrationSMS(data, callback) {
    apiNoToken.post(BASE_URL + 'auth/registrationToken', data).then(response => {
        localStorage.setItem('timestamp', response.data.result['timestamp']);
        localStorage.setItem('signature', response.data.result['signature']);
        callback(response);
    }).catch(error => {
        console.log(error);
    })
}

export function validateRegistrationSMS(data, callback) {
    apiNoToken.post(BASE_URL + 'auth/validateRegistration', data).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    })
}

export function registration(data, callback, error) {
    apiNoToken.post(BASE_URL + 'auth/register', data).then(response => {
        if (!response.data.errorCode || response.data.errorCode == 'undefined') {
            localStorage.setItem('token', response.data.result['Auth-Token']);
            localStorage.setItem('timestamp', response.data.result['timestamp']);
            localStorage.setItem('signature', response.data.result['signature']);
            localStorage.setItem('cashBackMin', response.data.result['cashBackMinimum']);
        }
        callback(response);
    }).catch(error);
}

export function completeRegistration(data, callback) {
    api.post('customer/completeRegistration', data).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    })
}

export function loginSMS(data, callback) {
    apiNoToken.post(BASE_URL + 'auth/loginSMS', data).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    })
}

export function jwtLoginSMS(data, callback) {
    apiNoToken.post(BASE_URL + 'auth/jwtLoginSMS', data).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    })
}

export function auth(data, callback) {
    apiNoToken.post(BASE_URL + 'auth/login', data).then(response => {
        callback(response);
    }).catch(error => {
        loginFailed();
        console.log(error);
    });
}

export function jwtAuth(data, callback) {
    apiNoToken.post(BASE_URL + 'auth/jwtLogin', data).then(response => {
        callback(response);
    }).catch(error => {
        loginFailed();
        console.log(error);
    });
}

/*export function activate(smsCode, data) {
    api.post('auth/validate', {'smsCode': smsCode}).then(response => {
        if(!response.data.errorCode){
            store.commit('setLoggedIn', true);

            if(localStorage.getItem('fromUnregisteredCalc') ){
                localStorage.removeItem('fromUnregisteredCalc');
                router.push('/transfer');
            }
            else{
                router.push('/recent_actions');
            }

        }
    }).catch(error => {
        console.log(error);
    });
}*/
export function getBeneficiaries(callback) {
    api.get('beneficiary').then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

function parseValidationErrors(response, errors, ml) {
    for (var i = 0; i < response.errors.length; i++) {
        let message, msgParam;
        try {
            let code = response.errors[i].code
            if (code.indexOf('passParam') > -1) {
                let lastIndex = code.lastIndexOf('.') + 1
                msgParam = code.substring(lastIndex)
                response.errors[i].code = response.errors[0].code.replaceAll(`.${msgParam}`, '')
            }
            message = ml.with('format', msgParam).get(response.errors[i].code) || ml.get('E_REGEX');
        } catch (error) {
            message = response.errors[i].message
        }
        errors.add({
            field: response.errors[i].field,
            msg: message
        });
    }
}

export function saveBeneficiary(data, errors, callback, ml) {
    api.post('beneficiary/save', data).then(response => {
        parseValidationErrors(response.data, errors, ml)
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function updateBeneficiary(data, errors, callback, ml) {
    api.put('beneficiary/update', data).then(response => {
        parseValidationErrors(response.data, errors, ml)
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function deleteBeneficiaries(data, callback) {
    api.delete('beneficiary/delete/' + data).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    })
}

export async function getBeneficiary(data) {
    try {
        const response = await api.post('beneficiary/get/' + data);
        return response.data.result;
    } catch (error) {
        console.log(error);
    }
}

export async function validateBeneficiary(data) {
    try {
        const response = await api.post('beneficiary/validate/', data);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

// actually fetches all the fields again
export async function getBeneficiaryDynamicFields(data) {
    try {
        const response = await api.post('beneficiary/dynamicFields', data);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export function getTransactions(data, callback) {
    api.get('/transaction/list/' + data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export async function activateMaxCard(data) {
    try {
        const response = await api.post('/card/activateCardByCid', data)
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getFilteredTransactionsAsync(data) {
    try {
        const response = await api.post('/transaction/list/', data)
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export function getCardTransactions(data, callback) {
    // callback(max_ex)
    api.post('/card/transactions', data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function getCards(callback) {
    api.post('/card/list').then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export async function getCardsAsync() {
    try {
        const response = await api.post('/card/list');
        return response.data.result;
    } catch (error) {
        console.log(error);
    }
}

export async function getCardData(data, callback) {
    try {
      const response = await api.post('/card/data', data)
      return response.data.result;
    } catch (error) {
        
    }
}

export function getCardBalance(data, callback) {
    api.post('/card/balance', data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function cardBlockUnblock(data, callback) {
    api.post('/card/cardBlockUnblock', data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function getPinCode(data, callback) {
    api.post('/card/getPinCode', data).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function getCashBackCreditAvailable(data, callback) {
    api.post('/account/cashBackAvailable', data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function preLookup(data, callback) {
    api.post('send/preLookup', data).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function preLookupOpen(data, callback) {
    apiNoToken.post(BASE_URL + 'send/preLookupOpen', data).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function feeLookup(data, callback) {
    api.post('send/feeLookup', data).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function feeLookupOpen(data, callback) {
    apiNoToken.post(BASE_URL + 'send/feeLookupOpen', data).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function paymentAmountInfo(data, callback) {
    api.post('send/paymentAmountInfo', data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export async function startTransaction(data) {
    try {
        const response = await api.post('/send/startTransaction ', data);
        return response.data.result;
    } catch (error) {
        console.log(error);
    }
}

export function startOrder(data, callback) {
    api.post('/order/startOrder', data).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function cancelWalletOrder(id, callback) {
    api.post(`/order/cancelOrder?id=${id}`).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function getOrderDetails(callback) {
    api.post('/order/getOrderDetails').then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function pollStatus(id, callback) {
    api.get(`/order/pollStatus?id=${id}`).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function sendValidation(data, callback) {
    api.post('/send/sendValidation ', data).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function commitTransaction(data, callback) {
    api.post('send/commitTransaction', data).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
        callback(error);
    })
}

export function checkStatus(data, callback) {
    api.post('transaction/checkStatus', data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function getBranches(callback) {
    axios.get('https://gmtonline.co.il/wp-json/wp/v2/branches').then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}


/* FILE UPLOAD */
export function uploadFile(data, callback, error) {
    api.post(BASE_URL + 'document/save', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
        callback(response);
    }).catch(error);
}

export function getDocuments(callback) {
    api.get('/document').then(response => {
        //console.log(response.data.result);
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function getDocumentsUrl(data, callback) {
    api.get(BASE_URL + 'document/getBase64/' + data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function deleteDocuments(data, callback) {
    api.delete('document/delete/' + data).then(() => {
        callback();
    }).catch(error => {
        console.log(error);
    })
}

export function deleteCustomerWebDocuments(callback) {
    api.delete('document/deleteAll').then(response => {
        //console.log(response);
        callback(response);
    }).catch(error => {
        console.log(error);
    })
}

//ADDRESSES
export function getCities(data, callback) {
    apiNoToken.post(BASE_URL + 'address/cities', data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function getStreets(data, callback) {
    apiNoToken.post(BASE_URL + 'address/cityStreets', data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

//CreditCard Payment
export function creditCardSign(data, callback) {
    api.post(BASE_URL + 'send/sign', data).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

// General
export function checkToken(callback) {
    api.post(BASE_URL + 'auth/checkToken').then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    });
}
export function setExternalIdOnesignal(callback) {
    api.post(BASE_URL + 'customer/setExternalIdOnesignal',{}, { swallowError: true }).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    });
}

export function updateLang(data) {
    console.log("update language");
    api.post(BASE_URL + 'customer/updateLang', data).catch(error => {
        console.log(error);
    });
}

export async function updateCustomer(data) {
    try {
        const response = await  api.post(BASE_URL + 'customer/update', data, {swallowError: true});
        return response.data;
    } catch (error) {
        console.log(error);
    }
}


export function polledBalances(callback, swallowError) {
    api.get(`/account/polledBalances`, { swallowError: swallowError }).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function getInstallmentsData(data, callback) {
    api.post('send/getInstallmentsData', data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function getCustomerByPhone(data, callback) {
    api.get('/customer/phone?mobileNumber=' + data).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function confirmBankDeposit(data) {
    api.post('/wallet/confirmBankDeposit/' + data).catch(error => {
        console.log(error);
    })
}

export async function addWalletFriend(data, callback) {
    api.post('/beneficiary/walletFriend/' + data).then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function walletFriends(callback) {
    api.get('/beneficiary/walletFriends').then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function deleteWalletFriend(id, callback) {
    api.get('/beneficiary/deleteWalletFriend/' + id).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    })
}

export function invitationCashBackAmount(callback) {
    api.get('/cashBack/invitationCashBack').then(response => {
        callback(response.data.result);
    }).catch(error => {
        console.log(error);
    })
}

export function acceptTermsVersion() {
    api.get('/customer/acceptTermsVersion').then().catch(error => {
        console.log(error);
    })
}

export function biometricLogin(data, callback) {
    apiNoToken.post(BASE_URL + '/auth/biometricLogin', data).then(res => {
        callback(res)
    }).catch(error => {
        console.log(error)
    })
}

export function registerBiometric(data, callback) {
    api.post(BASE_URL + '/biometric/registerBiometric', data).then(res => {
        callback(res.data.result)
    }).catch(error => {
        console.log(error)
    })
}
export function unregisterBiometric(callback) {
    api.post('/biometric/unregisterBiometric').then(res => {
        callback(res.data.result)
    }).catch(error => {
        console.log(error)
    })
}
export function profileDeleteRequest(callback) {
    api.post('/customer/profileDeleteRequest').then(res => {
        callback(res.data.result)
    }).catch(error => {
        console.log(error)
    })
}