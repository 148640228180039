<template>
    <modal ref="modalRef" image="/img/gifs/update_mail.gif" :title="$ml.get('customerEmailNotification.title')"
        :body="$ml.get('customerEmailNotification.body')" :confirm="$ml.get('btnDone')" @action="updateEmail"
        custom-close @closeAction="closeUpdateEmailModal" :cancel="$ml.get('customerEmailNotification.cancel')">
        <div class="form-group">
            <input type="email" id="email" name="email" class="form-control" style="letter-spacing: 0;"
                v-validate="'email'" v-model.trim="email">
            <span class="error">{{ errors.first('email') }}</span>
        </div>
    </modal>
</template>
<script>

import Modal from "@/components/Modal";
import { updateCustomer } from "../services/api";

export default {
    name: 'EmailUpdate',
    components: { Modal },
    data() {
        return {
            email: ''
        }
    },
    methods: {
        updateEmail() {

            if (this.email) {
                this.$validator.validate().then(result => {
                    if (result) {
                        updateCustomer({ email: this.email }).then(data => {
                            if (data && !data.errorCode) {
                                const registrationData = JSON.parse(localStorage.getItem('registrationData'));
                                registrationData.email = this.email;
                                localStorage.setItem('registrationData', JSON.stringify(registrationData));
                                this.$refs.modalRef.closeModal();
                            } else if (data.errorCode === 'EMAIL_ALREADY_EXISTS_UPDATE') {
                                this.$validator.errors.add({ field: 'email', msg: this.$ml.get('EMAIL_ALREADY_EXISTS_UPDATE') })
                            } else {
                                this.$refs.modalRef.closeModal();
                            }
                        });

                    }
                })
            } else {
                const field = this.$validator.fields.find({ name: 'email' });
                this.$validator.errors.add({ id: field.id, field: 'email', msg: this.dictionary()[this.getLang]['messages']['email'] });
                field.setFlags({ invalid: true, valid: false, validated: true });
            }
        },
        closeUpdateEmailModal() {
            localStorage.setItem('customerEmailLastNotificationDate', String(Date.now()))
            this.$refs.modalRef.closeModal();
        },
        dictionary() {
            return localesDictionary({ messages: { required: this.$ml.get("E_REQUIRED"), email: this.$ml.get("E_REGEX_email") } });
        },
        checkModalEmail() {
            const date = Number(localStorage.getItem('customerEmailLastNotificationDate'))
            const notificationExpired = !date || (Date.now() - 2592000000) > date
            const registrationData = JSON.parse(localStorage.getItem('registrationData'))
            if (registrationData && !registrationData.email && notificationExpired) {
                this.$refs.modalRef.openModal();
                return true;
            }
        },
    }
}



</script>